/*
lightgreen: #9cd9b7
green: #3cb36f
yellow: #ff0
red: rgba(253,103,103,.95)
*/
:root {
  /* header-height: (font-size * line-height) + padding-top + padding-bottom */
  --header-height: calc((1rem * 1.3) + .55rem + .5rem);
  /* footer-height: (font-size * line-height) + btn-padding-top + btn-padding-bottom + btn-border + padding-top + padding-bottom + footer-border-top */
  --footer-height: calc((.9375rem * 1.5) + 0.5rem + 0.5rem + 2px + 0.5rem + 1rem + 3px);
  --color-lightgreen: #ebf5ec;
  --color-logigreen: #d9f1db;
  --color-green: #06c755;
  --color-yellow: #ffff01;
  --color-lightyellow:#FFFFCB;
  --color-red: rgba(253,103,103,.95);
}
html {
  height: 100vh;
}

body {
  margin: 0;
  font-family: "Noto Sans JP",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
}

#root {
  height: 100vh;
  width: 100%;
}

.backyard-top {
  margin-top: 100px;
  font-size: small;
}

.liff-top {
  height:100vh;
  /* height: calc(var(--vh, 1vh) * 100); */
  width: 100%;
  overflow: hidden;
  touch-action: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*@page {
  size: 60mm 160mm;
  margin: 0mm;
}*/

.print-body {
  width: 260px;
  height: 700px;
  page-break-after: always;
}

.text-black {
  color:black;
}

.border-black {
  border-color: black!important;
}

.footer {
  /* position: fixed; */
  position: sticky; /* currently in development for MS Edge */
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  bottom: 0;
  width: 100%;
}

.header {
  top: 0;
  width: 100%;
}

.table-scroll {
  overflow-y: scroll;
}

.section {
  background-color: var(--color-green);
  color: white;
  text-align: center!important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0rem;
  padding-right: 0rem;
}
.header .section{
  background-color: white;
  position:relative;
  color:var(--color-green);
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 1.3;
  padding-top: 0.55rem;
  padding-bottom: 0.5rem;
  position: relative;
  text-align: center;
}

.header .section::before{
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='62.674' height='39.99'%3e%3cpath d='m8.705 5.67 3.757 3.11 3.756-3.11-3.756-3.11Zm3.757 5.66L5.624 5.67 12.462 0 19.3 5.67ZM52.873 39.99a5.439 5.439 0 0 1-5.431-5.43h1.966a3.466 3.466 0 0 0 3.466 3.46 3.466 3.466 0 0 0 3.466-3.46h1.966a5.439 5.439 0 0 1-5.431 5.43M41.171 39.99a5.439 5.439 0 0 1-5.431-5.43h1.966a3.466 3.466 0 0 0 3.466 3.46 3.466 3.466 0 0 0 3.466-3.46h1.966a5.439 5.439 0 0 1-5.431 5.43M22.401 39.99a5.439 5.439 0 0 1-5.431-5.43h1.966a3.466 3.466 0 0 0 3.466 3.46 3.466 3.466 0 0 0 3.466-3.46h1.966a5.44 5.44 0 0 1-5.432 5.43M10.701 39.99a5.44 5.44 0 0 1-5.432-5.43h1.966a3.466 3.466 0 0 0 3.466 3.46 3.466 3.466 0 0 0 3.466-3.46h1.966a5.439 5.439 0 0 1-5.431 5.43' fill='%233cb36f'/%3e%3cpath d='M62.673 34.69H0V14.58a3.949 3.949 0 0 1 3.947-3.94h54.78a3.949 3.949 0 0 1 3.947 3.94Z' fill='%233cb36f'/%3e%3cpath d='M1.816 23.89h4.085v-6.485H1.816ZM56.998 23.89h3.829v-6.485h-3.829ZM14.016 23.89h7.464v-6.485h-7.464ZM22.85 23.89h7.466v-6.485H22.85ZM32.19 23.89h7.464v-6.485H32.19ZM41.024 23.89h7.464v-6.485h-7.464Z' fill='%23ebf5eb' fill-rule='evenodd'/%3e%3cpath d='M27.116 8.1a.871.871 0 0 0-.869.87v.84a.871.871 0 0 0 .869.87h7.6a.871.871 0 0 0 .87-.87v-.84a.871.871 0 0 0-.87-.87Zm7.6 4.54h-7.6a2.836 2.836 0 0 1-2.835-2.83v-.84a2.838 2.838 0 0 1 2.835-2.84h7.6a2.838 2.838 0 0 1 2.835 2.84v.84a2.836 2.836 0 0 1-2.835 2.83M44.359 8.1a.871.871 0 0 0-.869.87v.84a.871.871 0 0 0 .869.87h7.6a.871.871 0 0 0 .87-.87v-.84a.871.871 0 0 0-.87-.87Zm7.6 4.54h-7.6a2.836 2.836 0 0 1-2.835-2.83v-.84a2.838 2.838 0 0 1 2.835-2.84h7.6a2.838 2.838 0 0 1 2.835 2.84v.84a2.836 2.836 0 0 1-2.835 2.83' fill='%233cb36f'/%3e%3cpath d='M1.806 28.86h59.282v-2.271H1.806Z' fill='%23ebf5eb' fill-rule='evenodd'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 46px auto;
  content: "";
  display: inline-block;
  height: 32px;
  position: absolute;
  left: 9px;
  bottom: 3px;
  width: 62px;
}
.header .section::after{
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='49.26' height='36.32'%3e%3cpath d='M32.491 26.22a6.678 6.678 0 0 0 6.672 6.67 6.677 6.677 0 0 0 6.673-6.67 6.677 6.677 0 0 0-6.673-6.67 6.678 6.678 0 0 0-6.672 6.67m-3.424 0a10.109 10.109 0 0 1 10.1-10.1 10.109 10.109 0 0 1 10.1 10.1 10.109 10.109 0 0 1-10.1 10.1 10.109 10.109 0 0 1-10.1-10.1M3.424 26.22a6.677 6.677 0 0 0 6.672 6.67 6.677 6.677 0 0 0 6.673-6.67 6.677 6.677 0 0 0-6.673-6.67 6.677 6.677 0 0 0-6.672 6.67M0 26.22a10.109 10.109 0 0 1 10.1-10.1 10.109 10.109 0 0 1 10.1 10.1 10.109 10.109 0 0 1-10.1 10.1A10.109 10.109 0 0 1 0 26.22' fill='%233cb36f'/%3e%3cpath d='m37.927 28.12 3.2-1.21-6.826-18.12H17.126v3.43h14.809Z' fill='%233cb36f'/%3e%3cpath d='m11.193 28.12 5.992-15.9h14.809V8.79H14.817L7.989 26.91Z' fill='%233cb36f'/%3e%3cpath d='M34.462 11.68 38.454 0h-8.54v3.42h3.751l-2.443 7.15Z' fill='%233cb36f'/%3e%3cpath d='M12.95 6.74h8.08V3.316h-8.08Z' fill='%233cb36f' fill-rule='evenodd'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 37px auto;
  content: "";
  display: inline-block;
  height: 30px;
  position: absolute;
  right: 7px;
  bottom: 4px;
  width: 40px;
}

.section span {
  color:white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.section-top {
  height: 2.40625rem;
  overflow: hidden;
}

.ReactModal__Body--open {
  position: fixed;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.px-0-env {
  padding-left: calc(0rem + env(safe-area-inset-left))!important;
  padding-right: calc(0rem + env(safe-area-inset-right))!important;
}
.px-1-env {
  padding-left: calc(0.25rem + env(safe-area-inset-left))!important;
  padding-right: calc(0.25rem + env(safe-area-inset-right))!important;
}
.px-2-env {
  padding-left: calc(0.5rem + env(safe-area-inset-left))!important;
  padding-right: calc(0.5rem + env(safe-area-inset-right))!important;
}
.px-3-env {
  padding-left: calc(1rem + env(safe-area-inset-left))!important;
  padding-right: calc(1rem + env(safe-area-inset-right))!important;
}

.p-env-bottom {
  padding-bottom: 1rem!important;
}

.p-1-env-bottom {
  padding-bottom: .25rem!important;
}

.p-2-env-bottom {
  padding-bottom: .5rem!important;
}

@supports(padding:max(0px)) {
  body, header, footer {
      padding-left: min(0vmin, env(safe-area-inset-left));
      padding-right: min(0vmin, env(safe-area-inset-right));
      padding-top: min(0vmin, env(safe-area-inset-top));
      padding-bottom: min(0vmin, env(safe-area-inset-bottom));
  }

  .p-env-bottom {
    padding-bottom: max(1rem, env(safe-area-inset-bottom))!important;
  }
  
  .p-1-env-bottom {
    padding-bottom: max(.25rem, env(safe-area-inset-bottom))!important;
  }
  
  .p-2-env-bottom {
    padding-bottom: max(.5rem, env(safe-area-inset-bottom))!important;
  }
}

@supports(margin:max(0px)) {
  body, header, footer {
    margin-left: min(0vmin, env(safe-area-inset-left));
    margin-right: min(0vmin, env(safe-area-inset-right));
    margin-top: min(0vmin, env(safe-area-inset-top));
    margin-bottom: min(0vmin, env(safe-area-inset-bottom));
  }
}

.page-btn {
  /* margin-top: 2.40625rem; */
  height: calc(100% - var(--header-height) - var(--footer-height));
  max-height: calc(100% - var(--header-height) - var(--footer-height) + 0.5rem - env(safe-area-inset-bottom, 0.5rem));
  /* height: calc(100% - 2.7578125rem + 0.1015625rem - max(1rem, env(safe-area-inset-bottom))); */
  /* height: calc(100% - 2.40625rem); */
  overflow-y: scroll;
  overscroll-behavior: none;
  --webkit-overflow-scrolling: touch;
}
.page-btn-total {
  /* margin-top: 2.40625rem; */
  height: calc(100% - var(--header-height) - var(--footer-height) - 1.875rem - env(safe-area-inset-bottom, 0rem));
  max-height: calc(100% - var(--header-height) - var(--footer-height) - 1.875rem);
  /* height: calc(100% - 2.7578125rem + 0.1015625rem - max(1rem, env(safe-area-inset-bottom))); */
  /* height: calc(100% - 2.40625rem); */
  overflow-y: scroll;
  overscroll-behavior: none;
  --webkit-overflow-scrolling: touch;
}
.page-full {
  /* margin-top: 2.40625rem; */
  max-height: calc(100% - var(--header-height));
  /* max-height: calc(100%); */
  overflow-y: scroll;
  overscroll-behavior: none;
  --webkit-overflow-scrolling: touch;
}
.page-full-noheader {
   max-height: 100%;
   overflow-y: scroll;
   overscroll-behavior: none;
   --webkit-overflow-scrolling: touch;
}
.text-alert {
  color: var(--color-red);
}

.tab-navs {
  overflow-x: scroll;
}

@media (min-width: 1400px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl {
      max-width:1340px
  }
}

@media (min-width: 1600px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl {
      max-width:1540px
  }
}

@media (min-width: 1920px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl {
      max-width:1840px
  }
}

.react-datepicker-popper {
  z-index: 999!important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.mx--1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

/* swiper */
.swiper-slide {
  width: 100%;
}
.swiper-pagination {
  position: relative !important;
  margin-top: 20px !important;
}
.swiper-button-disabled{
  display: none!important;
}
.swiper-button-next, .swiper-button-prev {
  width: 1rem!important;
  height: 0rem!important;
  --swiper-navigation-sides-offset: 0rem;
}
.swiper-button-next:after, .swiper-button-prev:after{
  font-size: 1.5rem!important;
}

/* 表示月以外の月の日を表示しない*/
.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
  background-color:#FFF !important;
}
.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover{
  background-color: #32be3f;
}
.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted{
  background-color: #3dcc4a
}
/* 選択日の背景色*/
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  background-color: #ff0!important;
  color:#3cb36f!important;
  border:2px solid #3cb36f!important;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover{
  background-color: #ff0!important;
}
@page{
  margin: 0mm;
}
@media print {
  body{
    -webkit-print-color-adjust: exact;
  }
  .print-list{
    page-break-after: avoid;
    font-size:small !important;
    padding: 8mm;
  }

  .print-none{
    display: none;
  }
  .table-striped > thead > tr > th{
    background-color:#cbeaff!important;
    color: #000 !important;
    border-color:#000000 !important;
  }
  .table-striped > tbody > tr > td{
    border-color:#000000 !important;
  }
  .table-striped > tbody > tr:nth-child(2n+1) > td{
    background-color:#cccccc !important;
    color: #000 !important;    
  }
  .table-striped > tbody > tr > td > table > tr > td{
    background-color:transparent!important;
  }
  .table-dept > thead > tr > th{
    background-color:#cbeaff!important;
    color: #000 !important;
    border-color:#000000 !important;
  }
  .table-dept > tbody > tr > td{
    border-color:#000000 !important;
  }
  
  .print-view{
    display: block;
  }
  
}
.preview-modal{
  width: 375px; /* iPhoneX size*/
  height: 80vh;
  max-height: 812px; /* iPhoneX size*/
}
.form-control{
  border-color: var(--color-green)!important;
}
.highlighted-slot {
  background-color: #ffc107; /* ハイライトの背景色 */
}
.custom-select{
  border-color: var(--color-green)!important;
  background-image:url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'  fill='currentColor'%3e%3cpath d='M4.427 7.427l3.396 3.396a.25.25 0 00.354 0l3.396-3.396A.25.25 0 0011.396 7H4.604a.25.25 0 00-.177.427z'%3e%3c/path%3e%3c/svg%3e");
  background-size: 1em;
}
.custom-control-label{
  margin-left:1rem;
}
.custom-control-input:checked~.custom-control-label::before{
  border-color: var(--color-green)!important;
  background-color: var(--color-green)!important;
}
.bg-green{
  background-color: var(--color-green)!important;
}
.bg-lightgreen{
  background-color: var(--color-lightgreen)!important;
}
.bg-logigreen{
  background-color: var(--color-logigreen)!important;
}
.bg-red{
  background-color: var(--color-red)!important;
}
.bg-yellow{
  background-color: var(--color-yellow)!important;
  color: var(--color-green)!important;
}
.bg-lightyellow{
  background-color: var(--color-lightyellow)!important;
}
.bg-black{
  background-color: #000;
  color: #FFFFFF;
}
.text-green{
  color: var(--color-green)!important;
}
.text-yellow{
  color: var(--color-yellow)!important;
}
.text-red{
  color: var(--color-red)!important;
}
.border-green{
  border-color: var(--color-green)!important;
}
.border-top-3{
  border-top: 3px solid ;
}
.border-gray{
  border-color: #ced4da!important;
}
.text-white{
  color: #FFFFFF!important;
}
.btn-danger{
  background:var(--color-red)!important;
}
.btn-primary{
  color: var(--color-green)!important;
  background:white!important;
  border-color:  var(--color-green)!important;
}
.btn-active,.btn-active:hover {
  color: #ffffff;
  background:var(--color-green);
  border-color:  var(--color-lightgreen);
}
.border-textbox{
  border: 1px solid var(--color-green);
  border-radius: 3px;
}
.btn-circle{
  width:2rem;
  height:2rem;
  border:2px solid var(--color-green);
  background-color: #FFF;
  color: var(--color-green);
  font-weight: bold;
}
.btn-enable {
  color: #212529;
  background:linear-gradient(#ffffff, #ededed);
  border-color: #658ff9;
  border-width: 1px;
}

.btn-submit-enable, .btn-submit-enable:hover {
  border-radius: 0;
  border:none;
}
.btn-submit, .btn-submit:hover {
  color: var(--color-green);
  border-radius: 0;
  border:none;
  background:var(--color-yellow);
}
.btn-active.disabled,.btn-active:disabled,.btn-submit.disabled, .btn-submit:disabled, .btn-submit-cancel.disabled, .btn-submit-cancel:disabled {
  color: #fff;
  background:linear-gradient(#b0b0b0, #9e9e9e);
  border-color: #b0b0b0;
}
.bi-chevron-right{
  position: relative;
}
.bi-chevron-right::after{
  background:url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#3cb36f' class='bi bi-chevron-right' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  position: absolute;
  top:auto;
  right: 2px;
  width: 1rem;
}
.btn-booking::before{
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 28px auto;
  content: "";
  display: inline-block;
  height: 32px;
  position: absolute;
  left: 12px;
  width: 62px;
}
.btn-ride::before{
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='62.674' height='39.99'%3e%3cpath d='m8.705 5.67 3.757 3.11 3.756-3.11-3.756-3.11Zm3.757 5.66L5.624 5.67 12.462 0 19.3 5.67ZM52.873 39.99a5.439 5.439 0 0 1-5.431-5.43h1.966a3.466 3.466 0 0 0 3.466 3.46 3.466 3.466 0 0 0 3.466-3.46h1.966a5.439 5.439 0 0 1-5.431 5.43M41.171 39.99a5.439 5.439 0 0 1-5.431-5.43h1.966a3.466 3.466 0 0 0 3.466 3.46 3.466 3.466 0 0 0 3.466-3.46h1.966a5.439 5.439 0 0 1-5.431 5.43M22.401 39.99a5.439 5.439 0 0 1-5.431-5.43h1.966a3.466 3.466 0 0 0 3.466 3.46 3.466 3.466 0 0 0 3.466-3.46h1.966a5.44 5.44 0 0 1-5.432 5.43M10.701 39.99a5.44 5.44 0 0 1-5.432-5.43h1.966a3.466 3.466 0 0 0 3.466 3.46 3.466 3.466 0 0 0 3.466-3.46h1.966a5.439 5.439 0 0 1-5.431 5.43' fill='%233cb36f'/%3e%3cpath d='M62.673 34.69H0V14.58a3.949 3.949 0 0 1 3.947-3.94h54.78a3.949 3.949 0 0 1 3.947 3.94Z' fill='%233cb36f'/%3e%3cpath d='M1.816 23.89h4.085v-6.485H1.816ZM56.998 23.89h3.829v-6.485h-3.829ZM14.016 23.89h7.464v-6.485h-7.464ZM22.85 23.89h7.466v-6.485H22.85ZM32.19 23.89h7.464v-6.485H32.19ZM41.024 23.89h7.464v-6.485h-7.464Z' fill='%23ebf5eb' fill-rule='evenodd'/%3e%3cpath d='M27.116 8.1a.871.871 0 0 0-.869.87v.84a.871.871 0 0 0 .869.87h7.6a.871.871 0 0 0 .87-.87v-.84a.871.871 0 0 0-.87-.87Zm7.6 4.54h-7.6a2.836 2.836 0 0 1-2.835-2.83v-.84a2.838 2.838 0 0 1 2.835-2.84h7.6a2.838 2.838 0 0 1 2.835 2.84v.84a2.836 2.836 0 0 1-2.835 2.83M44.359 8.1a.871.871 0 0 0-.869.87v.84a.871.871 0 0 0 .869.87h7.6a.871.871 0 0 0 .87-.87v-.84a.871.871 0 0 0-.87-.87Zm7.6 4.54h-7.6a2.836 2.836 0 0 1-2.835-2.83v-.84a2.838 2.838 0 0 1 2.835-2.84h7.6a2.838 2.838 0 0 1 2.835 2.84v.84a2.836 2.836 0 0 1-2.835 2.83' fill='%233cb36f'/%3e%3cpath d='M1.806 28.86h59.282v-2.271H1.806Z' fill='%23ebf5eb' fill-rule='evenodd'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 46px auto;
  content: "";
  display: inline-block;
  height: 2em;
  position: absolute;
  left: 12px;
  width: 62px;
}
.btn-ride::after{
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='49.26' height='36.32'%3e%3cpath d='M32.491 26.22a6.678 6.678 0 0 0 6.672 6.67 6.677 6.677 0 0 0 6.673-6.67 6.677 6.677 0 0 0-6.673-6.67 6.678 6.678 0 0 0-6.672 6.67m-3.424 0a10.109 10.109 0 0 1 10.1-10.1 10.109 10.109 0 0 1 10.1 10.1 10.109 10.109 0 0 1-10.1 10.1 10.109 10.109 0 0 1-10.1-10.1M3.424 26.22a6.677 6.677 0 0 0 6.672 6.67 6.677 6.677 0 0 0 6.673-6.67 6.677 6.677 0 0 0-6.673-6.67 6.677 6.677 0 0 0-6.672 6.67M0 26.22a10.109 10.109 0 0 1 10.1-10.1 10.109 10.109 0 0 1 10.1 10.1 10.109 10.109 0 0 1-10.1 10.1A10.109 10.109 0 0 1 0 26.22' fill='%233cb36f'/%3e%3cpath d='m37.927 28.12 3.2-1.21-6.826-18.12H17.126v3.43h14.809Z' fill='%233cb36f'/%3e%3cpath d='m11.193 28.12 5.992-15.9h14.809V8.79H14.817L7.989 26.91Z' fill='%233cb36f'/%3e%3cpath d='M34.462 11.68 38.454 0h-8.54v3.42h3.751l-2.443 7.15Z' fill='%233cb36f'/%3e%3cpath d='M12.95 6.74h8.08V3.316h-8.08Z' fill='%233cb36f' fill-rule='evenodd'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 37px auto;
  content: "";
  display: inline-block;
  height: 30px;
  position: absolute;
  right: 9px;
  width: 40px;
}



/* react-calendar 土曜日の文字を青色に*/
.react-calendar .react-calendar__month-view__days__day--weekend:nth-child(7n) {
  color: #00F;
}
.react-calendar .holiday{
  color: #F00!important;
}
 
/* react-calendar 来月の文字はグレーに */
.react-calendar .react-calendar__tile--now{
  background:white;
  color: #000;
}
.react-calendar .react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
.react-calendar .react-calendar__tile--active:enabled, 
.react-calendar .react-calendar__tile--active:enabled:hover, 
.react-calendar .react-calendar__tile--active:enabled:focus
.react-calendar .react-calendar__tile--active {
  color: #000!important;
  background:var(--color-lightgreen)!important;
}
.react-calendar .react-calendar__tile {
  vertical-align: top;
}
.upload-csv-file-area > div > div {
  width: 100% !important;
}

.nav-btn{
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
}

.status-stop {
  background-color: mistyrose !important;
}

.status-end {
  background-color: gainsboro !important;
}

.like-button {
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.qr-box {
  width: auto;
  height: auto;
  background-color: #ffffff;
  margin: auto auto;
}
.qr-img {
  margin: 3px 3px 3px 3px;
}

.size {
  font-size: 1.0rem !important;
}